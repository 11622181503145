<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("management") }} {{ $t("Property") }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="page">
        <ul class="box-info">
          <!-- <li>
              <i class="bx bxs-calendar-check"><i class="bi bi-people"></i></i>
              <span class="text">
                <h5>{{ $t("customers") }}</h5>
                <p>{{ $t("numbers") }} : {{ costomer }}</p>
              </span>
            </li> -->
          <!-- <li>
                        <i class="bx bxs-group"><i class="bi bi-reply-all-fill"></i></i>
                        <span class="text">
                            <h5>{{ $t("suppliers") }}</h5>
                            <p>{{ $t("numbers") }} : {{ suppliers }}</p>
                        </span>
                    </li> -->
          <!-- <li>
              <i class="bx bxs-dollar-circle"
                ><i class="bi bi-file-earmark-medical-fill"></i
              ></i>
              <span class="text">
                <h5>{{ $t("invoices") }} {{ $t("sales") }}</h5>
                <p>{{ $t("numbers") }} : {{ invoices }}</p>
              </span>
            </li> -->
          <!-- <li>
                        <i class="bx bxs-dollar-circle"><i class="bi bi-credit-card-2-front-fill"></i></i>
                        <span class="text">
                            <h5>{{ $t("bills") }}</h5>
                            <p>{{ $t("numbers") }} : {{ bills }}</p>
                        </span>
                    </li> -->
        </ul>
      </div>
      <h4>ادارة الاملاك</h4>
      <div class="body">
        <div class="part">
          <h6>{{ $t("real estate") }} / {{ $t("units") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/allproperties">{{ $t("all") }} {{ $t("real estate") }} /
                {{ $t("units") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/createproperty">{{ $t("add") }} {{ $t("real estate") }} / {{ $t("units") }}
                {{ $t("new") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("payment") }} / {{ $t("collection") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/allpayments">
                {{ $t("receipts") }} {{ $t("received") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/createpayment/0/0">{{ $t("add") }} {{ $t("receipts") }}
                {{ $t("received") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/AllOwnerReceipts">{{ $t("receipts") }} {{ $t("paid") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/CreateReceiptOwner">{{ $t("add") }} {{ $t("receipts") }}
                {{ $t("paid") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("customers") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/allcustomers">{{ $t("all") }} {{ $t("customers") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/createowner">{{ $t("add") }} {{ $t("customer") }}
                {{ $t("new") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("marketing") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/allpropertiespriceoffer">{{ $t("offer") }} {{ $t("prices") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/createpriceoffer">{{ $t("add") }} {{ $t("offer") }} {{ $t("price") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("reports") }}</h6>
          <ul>
            <!-- <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/renterstatement"
                >{{ $t("Account Statement") }} {{ $t("renter") }}
              </router-link>
            </li> -->
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/ownerstatement">{{ $t("Account Statement") }} {{ $t("owner") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/customerstatement">{{ $t("Account Statement") }} {{ $t("renter") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/rentalstatement">{{ $t("Statement") }} {{ $t("due") }} {{ $t("Rentals") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/RevenuesPropertiesReport">{{ $t("report") }} {{ $t("revenues") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/contractstatment">{{ $t("contracts") }} {{ $t("customers") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/TaxReportOwners">{{ $t("report") }} {{ $t("tax") }} {{ $t("owners") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/ExpiredContracts">{{ $t("report") }} {{ $t("contracts") }} {{ $t("Expired") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/property/ArchiveContracts">{{ $t("report") }} {{ $t("contracts") }} {{ $t("archive") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
// import store from "@/store";
// import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  props: ["css"],
  components: {},
  data() {
    return {
      costomer: 0,
      suppliers: 0,
      invoices: 0,
      bills: 0,
      salles: "",
    };
  },
  async mounted() { },
  async created() { },
  methods: {
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  display: grid;
  grid-template-columns: repeat(3, 5fr);
  grid-gap: 20px;
  padding: 1%;
  // min-height: 500px;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

#content main .box-info {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 24px;
  margin-top: 10px;
}

#content main .box-info li {
  width: 25%;
  padding: 2%;
  background: #f9f9f9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
}

#content main .box-info li .bx {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content main .box-info li .bx {
  background: v-bind("css.color");
  color: black;
}

#content main .box-info li .text h5 {
  font-size: 20px;
  font-weight: 600;
  color: #342e37;
}

#content main .box-info li .text p {
  color: #342e37;
  font-size: 14px;
}

@media screen and (max-width: 576px) {
  .body {
    display: grid;
    grid-template-columns: repeat(1, 5fr);
    grid-gap: 20px;
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .part {
    font-size: small;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
  }

  .bi {
    margin: 1%;
  }
}
</style>
