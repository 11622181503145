<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#" class="active">{{ $t("Property") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active">{{ $t("Account Statement") }} {{ $t("owner") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("owner") }}</span> &nbsp;&nbsp;
        <v-select :options="accounts" label="name" v-model="item"
          style="background-color: white; color: black; width: 30%">
          <template #option="option">
            <span style="color: black"> {{ option.name }}</span>
          </template> </v-select>&nbsp;&nbsp;
        {{ $t("from") }}
        <input type="date" :placeholder="$t('from')" v-model="from" />
        {{ $t("to") }}
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
      <div class="head2">
        <button type="submit" @click="pdf">
          <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
        </button>
        <button type="submit" @click="exportToExcel">
          <i class="bi bi-file-earmark-excel"></i> Excel
        </button>
      </div>
    </div>
    <div class="head" v-if="this.alldata.length > 0">
      <div class="head1">
        <span>نوع العقد</span> &nbsp;&nbsp;
        <select v-model="type">
          <option value="commercial">{{ $t("commercial") }}</option>
          <option value="residential">{{ $t("residential") }}</option>
        </select>
      </div>
    </div>
    <div class="body" v-if="this.alldata.length > 0">
      <!-- <img :src="this.url" crossorigin="anonymous" /> -->
      <br />
      <div style="padding: 2%; direction: rtl; font-size: small">
        <h4>
          {{ $t("owner") }} - {{ owner }}
          <span v-if="css.lang == 'ar'">{{ namear }}</span><span v-if="css.lang == 'en'">{{ nameen }}</span> {{
            view }}
        </h4>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>

        <table class="table">
          <thead>
            <tr>
              <th>رقم العقار</th>
              <th>اسم العقار</th>
              <th>اسم المستاجر</th>
              <th>رقم العقد</th>
              <th>نوع العقد</th>
              <th>مبلغ الاستحقاق</th>
              <th>الضريبة</th>
              <th>تاريخ الاستحقاق</th>
              <th>تاريخ السداد</th>
              <!-- <th>فاتورة ضريبية</th> -->
              <th>حالة فاتورة الهيئة</th>

              <th>اجراءات</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, id) in alldata" :key="i">
              <td>
                <router-link to="">{{ i.properyid }}</router-link>
              </td>
              <td>{{ i.propertyname }}</td>
              <td>{{ i.rentername }}</td>
              <td>{{ i.igarnumber }}</td>
              <td>
                <span v-if="i.conttype != null"> {{ $t(i.conttype) }}</span>
              </td>
              <td>{{ i.dueamount }}</td>
              <td>{{ i.duetax }}</td>

              <td>{{ i.duedate }}</td>
              <td>
                <span v-if="i.invdate == 'null'">{{ i.duedate }}</span>
                <span v-else>{{ i.invdate }}</span>
              </td>
              <!-- <td>{{ i.invnum }}</td> -->
              <td>
                <span v-if="i.invoice_data && (i.invoice_data === 'pending' || !i.invoice_data)" class="text-warning">لم
                  ترسل
                  بعد
                </span>
                <span v-else-if="i.invoice_data && i.invoice_data === 'sent_to_zatca'" class="text-primary">ارسلت
                </span>
                <span v-else-if="i.invoice_data && i.invoice_data === 'receive_from_zatca'" class="text-success">تم
                  الاستلام
                  الفاتوره</span>
                <span v-else class="text-muted">Unknown Status</span>
              </td>

              <td>
                <!-- Button trigger modal -->
                <button v-if="!i.invoice_data || (i.invoice_data && !i.invoice_data.fai_invoice_preview_links)"
                  type="button" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="'#exampleModal' + id"
                  @click="getdata(i)">
                  <i class="bi bi-printer-fill"></i>
                </button>
                <!-- Button trigger modal -->
                <span v-if="!i.sendToZatca">
                  <button :disabled="i.loading"
                    v-if="!i.invoice_data || (i.invoice_data && i.invoice_data.status == 'pending')" type="button"
                    class="btn btn-primary" @click="sendToZatca(i)">
                    <span v-if="i.loading">Sending...</span>
                    <span v-else>ترحيل</span>
                  </button>
                </span>
                <span v-else class="badge bg-success" style="font-size: 10px;">ارسلت</span>

                <span
                  v-if="i.invoice_data && i.invoice_data.status == 'sent_to_zatca' && i.invoice_data.fai_invoice_preview_links == null"
                  class="badge bg-success" style="font-size: 10px;">أرسلت </span>
                <span v-else-if="i.invoice_data && i.invoice_data.fai_invoice_preview_links != null">
                  <button type="button" :href="i.invoice_data.fai_invoice_preview_links"
                    @click="openLink(i.invoice_data.fai_invoice_preview_links)" target="_blank" class="btn btn-primary"
                    title="Download">
                    <i class="bi bi-printer-fill"></i>
                  </button>

                  <button v-if="!i.invoice_data.is_returned || i.invoice_data.returned_invoice_preview_links == null"
                    type="button" @click="ReturnInvoice(i)" target="_blank" class="btn btn-primary">
                    استرجاع
                  </button>
                  <button v-else type="button" @click="openLink(i.invoice_data.returned_invoice_preview_links)"
                    target="_blank" class="btn btn-primary">
                    فاتورة الاسترجاع
                  </button>
                </span>
                <div v-if="i.loading" class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>

                <!-- Modal -->
                <div class="modal fade" :id="'exampleModal' + id" tabindex="-1" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          فاتورة ضريبية
                        </h5>
                      </div>
                      <div class="modal-body">
                        <div id="main2" style="padding: 2%">
                          <div style="
                                                      padding: 2%;
                                                      direction: rtl;
                                                      font-size: small;
                                                    ">
                            <!-- <img src="./logo.png" style="height: 200px; width: 100%" /> -->
                            <img :src="this.url" crossorigin="anonymous"
                              style="height: 100px; width: 25%; float: right" />
                            <qrcode-vue style="float: left" render-as="svg" :value="qrCodeString" :size="150"
                              level="H" />

                            <div class="part1" style="clear: both; text-align: right">
                              <div class="part11">
                                <h3>فاتورة ضريبية
                                  <span
                                    v-if="!(i.invoice_data && i.invoice_data.status == 'sent_to_zatca' && i.invoice_data.fai_invoice_preview_links == null)">(مسوده)</span>

                                </h3>
                                {{ $t("number") }} : own-{{ invnum }}
                                {{ this.invoicenumcom }}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {{ $t("date") }} : {{ invduedate }}
                                <br />
                              </div>
                              <hr />
                              <div class="part12">
                                <!-- <h4>معلومات البائع</h4> -->
                                <div style="padding: 2%; text-align: right">
                                  <h5>{{ owner }}</h5>
                                  {{ owneraddress }}
                                  <br />
                                  رقم الوحدة : {{ unitenum }}
                                  <br />
                                  رقم تسجيل ضريبة القيمة المضافة :
                                  {{ ownertaxnum }}
                                </div>
                              </div>
                              <div class="part12">
                                <div style="padding: 2%">
                                  اسم العميل :
                                  <h5>{{ rentername }}</h5>
                                  رقم العقد :
                                  {{ igarnumber }}
                                  <br />
                                  عنوان :
                                  {{ cdata.address }}
                                  <br />
                                  رقم تسجيل ضريبة القيمة المضافة :
                                  {{ cdata.taxnumber }}
                                </div>
                              </div>
                            </div>
                            <br />
                            <div class="part2">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>{{ $t("type") }}</th>
                                    <th>{{ $t("description") }}</th>
                                    <th>{{ $t("amount") }}</th>
                                    <th>{{ $t("tax") }}</th>
                                    <th>{{ $t("total") }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>قسط ايجار لعقد رقم {{ igarnumber }}</td>
                                    <td>{{ details }}</td>
                                    <td>{{ amount }}</td>
                                    <td>{{ tax }}</td>
                                    <td>
                                      {{
                                        Number(
                                          Number(amount) + Number(tax)
                                        ).toFixed(2)
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <hr />
                            <div style="width: 20%;margin-right: 70%;">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>الاجمالى قبل الضريبة</td>
                                    <td>{{ amount }}</td>
                                  </tr>
                                  <tr>
                                    <td>ضريبة القيمة المضافة 15%</td>
                                    <td>{{ tax }}</td>
                                  </tr>
                                  <tr>
                                    <td>الاجمالى</td>
                                    <td> {{
                                      Number(
                                        Number(amount) + Number(tax)
                                      ).toFixed(2)
                                    }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <hr />
                            {{ $t("description") }} :
                            {{ description }}
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                          {{ $t("close") }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="pdf2">
                          {{ $t("printe") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                &nbsp;&nbsp;
                <!-- Button trigger modal -->
                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  :data-bs-target="'#exampleModal02' + id" @click="getdata(i)" v-if="hasPermission('property_delete')">
                  <i class="bi bi-pencil-square"></i>
                </button>

                <!-- Modal -->
                <div class="modal fade" :id="'exampleModal02' + id" tabindex="-1" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-l">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          تعديل فاتورة ضريبية
                        </h5>
                      </div>
                      <div class="modal-body">
                        <div class="mb-1 row">
                          <label class="col-sm-2 col-form-label">{{ $t("date") }} {{ $t("invoice")
                            }}
                          </label>
                          <div class="col-sm-4">
                            <input type="date" class="form-control" v-model="invoicedate" />
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                          {{ $t("close") }}
                        </button>
                        <button type="button" class="btn btn-primary" v-if="hasPermission('property_delete')"
                          @click="edite(i)">
                          {{ $t("Edite") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">الاجمالى</td>
              <td>{{ total }}</td>
              <td>{{ totaltax }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="body" v-if="this.alldata.length > 0" id="main" style="display: none">
      <!-- <img :src="this.url" crossorigin="anonymous" /> -->
      <br />
      <div style="padding: 2%; direction: rtl; font-size: small">
        <h4>
          {{ $t("owner") }} - {{ owner }}
          <span v-if="css.lang == 'ar'">{{ namear }}</span><span v-if="css.lang == 'en'">{{ nameen }}</span> {{
            view }}
        </h4>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>

        <table class="table">
          <thead>
            <tr>
              <th>رقم العقار</th>
              <th>اسم العقار</th>
              <th>اسم المستاجر</th>
              <th>رقم العقد</th>
              <th>نوع العقد</th>
              <th>مبلغ الاستحقاق</th>
              <th>الضريبة</th>
              <th>تاريخ الاستحقاق</th>
              <th>تاريخ السداد</th>
              <th>رقم الفاتورة</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in alldata" :key="i">
              <td>
                <router-link to="">{{ i.properyid }}</router-link>
              </td>
              <td>{{ i.propertyname }}</td>
              <td>{{ i.rentername }}</td>
              <td>{{ i.igarnumber }}</td>
              <td>
                <span v-if="i.conttype != null"> {{ $t(i.conttype) }}</span>
              </td>
              <td>{{ i.dueamount }}</td>
              <td>{{ i.duetax }}</td>

              <td>{{ i.duedate }}</td>

              <td>
                <span v-if="i.invdate == 'null'">{{ i.duedate }}</span>
                <span v-else>{{ i.invdate }}</span>
              </td>
              <td>{{ i.invnum }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">الاجمالى</td>
              <td>{{ total }}</td>
              <td>{{ totaltax }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import store from "@/store";
import * as XLSX from "xlsx";
import QrcodeVue from "qrcode.vue";
import { Buffer } from "buffer";
import Swal from "sweetalert2";
export default {
  components: {
    vSelect,
    QrcodeVue,
  },
  async mounted() {
    const result2 = await axios.get("/data/allowners");
    if (result2.status == 200) {
      const cust = result2.data.data;
      this.accounts = cust;
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: "",
      todate: "",
      alldata: [],
      alldata2: [],
      accounts: "",
      from: "",
      to: "",
      url: "",
      total: 0,
      totaltax: 0,
      type: "",
      owner: "",
      owneraddress: "",
      ownertaxnum: "",
      qrCodeString: "",
      invduedate: "",
      cdata: {},
      rentername: "",
      igarnumber: "",
      amount: "",
      tax: "",
      invnum: "",
      unitenum: "",
      details: "",
      invoicedate: "",
    };
  },
  methods: {
    async submit() {
      this.alldata2 = [];
      this.alldata = [];
      this.type = "";
      const result = await axios.get(
        `/reports/taxreportowners/${this.from}/${this.to}/${this.item.cid}`
      );
      if (result.status == 200) {
         this.fromdate = this.from;
        this.todate = this.to;
        this.owner = this.item.name;
        this.owneraddress = this.item.address;
        this.ownertaxnum = this.item.taxnumber;

        this.alldata2 = result.data.data;
        this.alldata = result.data.data;
        // for (let i = 0; i < this.alldata.length; i++) {
        //   const element = this.alldata[i];
        //   const invnum = await axios.get(
        //     `/reports/getownerinvnumber/${element.id}/${element.duedate}`
        //   );
        //   if (invnum.status == 200) {
        //     this.alldata[i].invnum = invnum.data.data[0].count;
        //   }
        // }
        if (this.alldata.length == 0) {
          alert("لا يوجد معاملات");
        }
        this.total = Number(
          this.alldata.reduce((acc, array) => acc + Number(array.dueamount), 0)
        ).toFixed(2);
        this.totaltax = Number(
          this.alldata.reduce((acc, array) => acc + Number(array.duetax), 0)
        ).toFixed(2);
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.1,
        filename: `Statement-${this.item.name}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },

    pdf2() {
      html2pdf(document.getElementById("main2").innerHTML, {
        margin: 0.5,
        filename: `${this.owner}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    async getdata(i) {
      // const invnum = await axios.get(
      //   `/reports/getownerinvnumber/${i.id}/${i.duedate}`
      // );
      // if (invnum.status == 200) {
      //   this.invnum = invnum.data.data[0].count;
      // }
      this.invnum = i.invnum;
      if (i.invdate == null) {
        this.invduedate = i.duedate;
      } else {
        this.invduedate = i.invdate;
      }

      this.rentername = i.rentername;
      this.igarnumber = i.igarnumber;
      this.amount = i.dueamount;
      this.tax = i.duetax;
      this.unitenum = i.unite;
      const due = i.due[0].due;
      const duedate = moment(i.duedate).add(due, "M").format("YYYY-MM-DD");
      this.details = `عن الفترة من ${i.duedate} الى ${duedate}`;
      const action = await axios.get(`/data/delcustomer/${i.renterid}`);
      if (action.status == 200) {
        this.cdata = action.data.data[0];
      }
      const encoded = Buffer.concat([
        this.tlvEncode(0x01, this.owner),
        this.tlvEncode(0x02, this.ownertaxnum),
        this.tlvEncode(0x03, this.invduedate),
        this.tlvEncode(0x04, Number(Number(this.amount) + Number(this.tax)).toFixed(2)),
        this.tlvEncode(0x05, this.tax),
      ]);
      this.qrCodeString = Buffer.from(encoded).toString("base64");
    },
    tlvEncode(tag, value) {
      const valueBytes = new TextEncoder().encode(value);
      const length = valueBytes.length;
      return Buffer.from([tag, length, ...valueBytes]);
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.alldata.length; i++) {
        const name = this.alldata[i];
        data.push({
          رقم_العقار: name.properyid,
          اسم_العقار: name.propertyname,
          اسم_المستاجر: name.rentername,
          رقم_العقد: name.igarnumber,
          نوع_العقد: name.conttype,
          مبلغ_الاستحقاق: name.dueamount,
          الضريبة: name.duetax,
          تاريخ_الاستحقاق: name.duedate,
          رقم_الفاتورة: name.invnum
        });
      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, `statement${this.owner}.xlsx`);
    },
    print2() {
      this.$htmlToPaper("main");
    },
    openLink(fai_invoice_preview_links) {
      window.open(fai_invoice_preview_links, "_blank");
    },
    async ReturnInvoice(duedate) {
      duedate.loading = true;
      console.log(duedate.invoice_data.id);

      const showToast = (icon, title) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({ icon, title });
      };

      try {
        const response = await axios.post("/reports/return-tax-report-to-zatca", {
          unified_invoice_id: duedate.invoice_data.id,
        });

        if (response.status === 200) {
          if (response.data.success) {
            duedate.sendToZatca = true;
            showToast("success", response.data.message || "تم الاسترجاع بنجاح!");
          } else {
            const errorMessage = response.data.message || "فشل فى الاسترجاع.";
            const errorDetails = response.data.errors
              ? Object.entries(response.data.errors)
                .map(([field, errors]) => `${field}: ${errors.join(", ")}`)
                .join("\n")
              : "";

            showToast("error", `${errorMessage}\n${errorDetails}`);
          }
        } else {
          showToast("error", "Unexpected response from server.");
        }
      } catch (error) {
        console.error("Error sending report to ZATCA:", error);
        const errorMessage =
          error.response?.data?.message || "An error occurred while sending the report. Please try again.";
        showToast("error", errorMessage);
      } finally {
        duedate.loading = false;
      }
    },
    async edite(i) {
      const result = await axios.patch(
        `/reports/editedateinvoice/${i.id}/${this.invoicedate}`
      );
      if (result.status == 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
      }
    },

    async sendToZatca(duedate) {
      duedate.loading = true;

      const showToast = (icon, title) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({ icon, title });
      };

      try {
        const response = await axios.post("/reports/send-tax-report-to-zatca", {
          dueDateId: duedate.id,
        });

        if (response.status === 200) {
          if (response.data.success) {
            duedate.sendToZatca = true;
            showToast("success", response.data.message || "Report sent successfully!");
          } else {
            const errorMessage = response.data.message || "Failed to send report to ZATCA.";
            const errorDetails = response.data.errors
              ? Object.entries(response.data.errors)
                .map(([field, errors]) => `${field}: ${errors.join(", ")}`)
                .join("\n")
              : "";

            showToast("error", `${errorMessage}\n${errorDetails}`);
          }
        } else {
          showToast("error", "Unexpected response from server.");
        }
      } catch (error) {
        console.error("Error sending report to ZATCA:", error);
        const errorMessage =
          error.response?.data?.message || "An error occurred while sending the report. Please try again.";
        showToast("error", errorMessage);
      } finally {
        duedate.loading = false;
      }
    }



  },
  watch: {
    type() {
      this.alldata = this.alldata2.filter((x) => x.conttype == this.type);
      this.total = Number(
        this.alldata.reduce((acc, array) => acc + Number(array.dueamount), 0)
      ).toFixed(2);
      this.totaltax = Number(
        this.alldata.reduce((acc, array) => acc + Number(array.duetax), 0)
      ).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
  display: flex;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
  font-size: x-small;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
