import { createRouter, createWebHistory } from "vue-router";
import store from "../store"; // Import the store

import signIn from "../views/LoginView.vue";
import AdminDashboard from "../views/AdminDashbord.vue";
import Home from "../views/HomePage.vue";
import ChangePassword from "../views/ChangePassword.vue";
import RAndP from "../views/RAndP.vue";
// import HomeSite from "../views/HomeSite.vue";
import AllContracts from "../views/AllContracts.vue";
import RoleList from "../views/Role/RoleList";
import RoleAdd from "../views/Role/RoleAdd";
import RoleEdit from "../views/Role/RoleEdit";

// Sales
import Customers from "../views/sales/CustomersView.vue";
import NewCustomer from "../views/sales/NewCustomer.vue";
import CustomerCategorie from "../views/sales/CustomerCategorie.vue";
import ImportCustomerData from "../views/sales/ImportCustomerData.vue";
import EditeCustomer from "../views/sales/EditeCustomer.vue";
import customerdetails from "../views/sales/CustomerDetails.vue";
import NewSupplier from "../views/sales/NewSupplier.vue";
import OfferPrice from "../views/sales/OfferPrice.vue";
import CreateOfferPrice from "../views/sales/CreateOfferPrice.vue";
import OfferPriceView from "../views/sales/OfferPriceView.vue";
import SupplyOrder from "../views/sales/SupplyOrder.vue";
import CreateSupplyOrder from "../views/sales/CreateSupplyOrder.vue";

import supplierdetails from "../views/sales/SupplierDetails.vue";
// Products
import AllProducts from "../views/products/AllProducts.vue";
import NewProducts from "../views/products/AddNewProduct.vue";
import NewProducts2 from "../views/products/NewProduct2.vue";
import ProductCategories from "../views/products/ProductCategories.vue";
import AddNewProductCategories from "../views/products/AddNewProductCategories.vue";
import EditeProductCategories from "../views/products/EditeProductCategories.vue";
import UnitTypes from "../views/products/UnitTypes.vue";
import AddNewUnitType from "../views/products/AddNewUnitType.vue";
import productview from "../views/products/ProductView.vue";
import productedite from "../views/products/ProductEdit.vue";
import AllProduction from "../views/products/AllProductions.vue";
import AddProdction from "../views/products/AddProduction.vue";
// branches
import AllBranches from "../views/products/AllBranches.vue";
import AddNewBranch from "../views/products/AddNewBranch.vue";
// Accounts
import ChartOfAccounts from "../views/accounts/ChartOfAccounts.vue";
import AddAccount from "../views/accounts/CreateNewAccount.vue";
import JournalEntry from "../views/accounts/JournalEntry.vue";
import CreateJournalEntry from "../views/accounts/CreateJournalEntry.vue";
import JournalEntryReview from "../views/accounts/JournalEntryReview.vue";
import OpeningBalance from "../views/accounts/OpeningBalance.vue";
import OpeningBalance2 from "../views/accounts/OpeningBalance2.vue";
import AllOperationExpenses from "../views/accounts/AllOperationExpenses.vue";
import DelegatesDues from "../views/accounts/DelegatesDues.vue";
import AllStoresTransactions from "../views/accounts/AllStoresTransactions";

// projects
import AllProjects from "../views/projects/AllProjects.vue";
import NewProject from "../views/projects/NewProject.vue";
//  tasks
import AllTasks from "../views/projects/AllTasks.vue";
import NewTask from "../views/projects/NewTask.vue";
// reports
import AllReports from "../views/Reports/AllReports.vue";
import BalanceSheet from "../views/Reports/BalanceSheet.vue";
// users
import UserView from "../views/users/UserView.vue";
import UserEdite from "../views/users/UserEdite.vue";
import AddUser from "../views/users/AddUser.vue";
import CompanyContracts from "../views/users/CompanyContracts.vue";
import AddCompanyContracts from "../views/users/AddCompanyContracts.vue";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//accounting
import accounting from "../views/accounts/AccountingModul.vue";
import homeaccounting from "../views/accounts/HomeAccounting.vue";
import costcenter from "../views/accounts/CostCenter.vue";
import addcostcenter from "../views/accounts/AddCostCenter.vue";
import JournalReport from "../views/accounts/JournalReport.vue";
import GeneralLedgerSummary from "../views/accounts/GeneralLedgerSummary.vue";
import GeneralLedgerSummaryMonthly from "../views/accounts/GeneralLedgerSummaryMonthly.vue";
import GeneralLedgerSummaryDaily from "../views/accounts/GeneralLedgerSummaryDaily.vue";
import TrialBalance from "../views/accounts/TrialBalance.vue";
import IncomeStatement from "../views/accounts/IncomeStatement.vue";
import FinancialCenter from "../views/accounts/FinancialCenter.vue";
import FastJL from "../views/accounts/FastJL.vue";
import CreateJEntryTemplete from "../views/accounts/JEntryTemplete.vue";
import CreateJournalEntryFast from "../views/accounts/CreateJournalEntryFast.vue";
import AllCommissions from "../views/accounts/AllCommissions.vue";
import AddCommission from "../views/accounts/CreateCommission.vue";
import RevenuesReport from "../views/accounts/RevenuesReport.vue";
// purchases
import purchases from "../views/purchases/PurchasesModul.vue";
import homepurchases from "../views/purchases/HomePurchases.vue";
import AllPurchaseOrder from "../views/purchases/AllPurchaseOrder.vue";
import PurchaseOrderView from "../views/purchases/PurchaseOrderView.vue";
import CreatePurchaseOrder from "../views/purchases/CreatePurchaseOrder.vue";
import suppliers from "../views/purchases/SuppliersView.vue";
import AllBills from "../views/purchases/AllBills.vue";
import BillView from "../views/purchases/BillView.vue";
import CreateBill from "../views/purchases/CreateBill.vue";
import SuplierStatement from "../views/purchases/SuplierStatement.vue";
import AllSupplierReceipts from "../views/purchases/AllSupplierReceipts.vue";
import CreateSupplierReceipt from "../views/purchases/CreateSupplierReceipt.vue";
// sales
import sales from "../views/sales/SalesModul.vue";
import homeSales from "../views/sales/HomeSales.vue";
import CustomerStatement from "../views/sales/CustomerStatement.vue";
import AllInvoices from "../views/sales/AllInvoices.vue";
import CreateInvoice from "../views/sales/CreateInvoice.vue";
import InvoiceView from "../views/sales/InvoiceView.vue";
import SupplyOrderView from "../views/sales/SupplyOrderView.vue";
import AllReceipts from "../views/sales/AllReceipts.vue";
import CreateReceipt from "../views/sales/CreateReceipt.vue";
import AddProductsToCustomer from "../views/sales/AddProductsToCustomer.vue";
// stock
import StockModul from "@/views/stock/StockModul.vue";
import HomeStock from "../views/stock/HomeStock.vue";
import AllBuyAndSell from "../views/stock/AllBuyAndSell.vue";
import AllBuyAndSellP from "../views/stock/AllBuyAndSellP.vue";
import StockBalance from "../views/stock/StockBalance.vue";
import ProductStockBalance from "../views/stock/ProductStockBalance";
import damagedproducts from "../views/stock/DamagedProducts.vue";
// tasks
import TaskModul from "@/views/TaskView/TaskModul.vue";
import TaskHome from "../views/TaskView/TaskHome.vue";
import CreateNewTask from "../views/TaskView/CreateNewTask.vue";
import TaskView from "../views/TaskView/TaskView.vue";
import AllTasksView from "../views/TaskView/AllTasks.vue";
import collectingorders from "../views/TaskView/CollectingOrders.vue";
import AllSupplyOrder from "../views/TaskView/AllSupplyOrders.vue";
import supplierorder from "../views/TaskView/SuppluOrderView.vue";
import CreateNewPO from "../views/TaskView/CreateNewPO.vue";
// CRM
import CRMModul from "@/views/CRM/CRMModul.vue";
import HomeCRM from "../views/CRM/HomeCRM.vue";
import CreateNewRequest from "../views/CRM/CreateNewRequest.vue";
import AllRequestsView from "../views/CRM/AllRequestsView.vue";
import Requestview from "../views/CRM/RequestView.vue";

// Property
import property from "../views/property/PropertyModul.vue";
import HomeProperty from "../views/property/HomeProperty.vue";
import allproperties from "../views/property/AllProperties.vue";
import createproperty from "../views/property/CreateProperty.vue";
import createowner from "../views/property/CreateOwner.vue";
import allcustomers from "../views/property/AllCustomers.vue";
import propertyview from "../views/property/PropertyView.vue";
import propertydetails from "../views/property/PropertyDetails.vue";
import createpayment from "../views/property/CreatePayment.vue";
import allpayments from "../views/property/AllPayments.vue";
import renterstatement from "../views/property/RenterStatement.vue";
import ownerstatement from "../views/property/OwnerStatement.vue";
import rentalstatement from "../views/property/RentalsStatement.vue";
import Customerstatement from "../views/property/CustomerStatement.vue";
import CustomerView from "../views/property/CustomerView.vue";
import ContactView from "../views/property/ContractView.vue";
import CreateContract from "../views/property/CreateContract.vue";
import PaymentView from "../views/property/PaymentView.vue";
import createpriceoffer from "../views/property/CreatePriceOffer.vue";
import allpropertiespriceoffer from "../views/property/AllPropertiesPriceOffer.vue";
import propertypriceofferview from "../views/property/PropertyPriceOfferView.vue";
import CreateReceiptOwner from "../views/property/CreateReceipt.vue";
import AllOwnerReceipts from "../views/property/AllReceipts.vue";
import ownerreceiptview from "../views/property/ReceiptView.vue";
import RevenuesPropertiesReport from "../views/property/RevenuesReport.vue";
import RevenuesReportDetails from "../views/property/RevenuesReportDetails.vue";
import contractstatment from "../views/property/ContractStatment";
import TaxReportOwners from "../views/property/TaxReportOwners.vue";
import Error403 from "../views/errors/403.vue";
import Error404 from "../views/errors/404.vue";
import rolesRoutes from "../routes/rolesRoutes";
import ExpiredContracts from "@/views/property/ExpiredContracts.vue";
import ArchiveContracts from "@/views/property/ArchiveContracts.vue";
// ProjectsManagement
import ProjectsManagement from "../views/projectsmanagement/ProjectsModul.vue";
import HomeProjects from "../views/projectsmanagement/HomeProjects.vue";
import allprojects from "../views/projectsmanagement/AllProjects.vue";
import createproject from "../views/projectsmanagement/CreateProject.vue";
import projectview from "../views/projectsmanagement/ProjectView.vue";
import AllProjectsBills from "../views/projectsmanagement/AllBills.vue";
import CreateProjectsBill from "../views/projectsmanagement/CreateBill.vue";
import ProjectsBillView from "../views/projectsmanagement/BillView.vue"
import contractors from "../views/projectsmanagement/ContractorsView.vue"
import newcontractor from "../views/projectsmanagement/NewContractors.vue"
import allcontractorscontracts from "../views/projectsmanagement/ContractorContracts.vue"
import createcontractorcontract from "../views/projectsmanagement/CreateContractorContract.vue"
import AllExtracts from "@/views/projectsmanagement/AllExtracts.vue";
import CreateExtract from "@/views/projectsmanagement/CreateExtract.vue";
import extractview from "@/views/projectsmanagement/ExtractView.vue";
import AllExtractsPayment from "@/views/projectsmanagement/AllPayments.vue";
import CreateReceiptProject from "@/views/projectsmanagement/CreateReceiptProject.vue";
import ReceiptProject from "@/views/projectsmanagement/ReceiptProjectView.vue";
import AllReceivePatty from "@/views/projectsmanagement/ReceivePetty.vue";
import CreateReceivePatty from "@/views/projectsmanagement/CreateReceivePatty.vue";
import receivepattyview from "@/views/projectsmanagement/ReveivePattyView.vue";
import pattystatement from "@/views/projectsmanagement/PattyStatement.vue";
import CostReport from "@/views/projectsmanagement/CostReportDetails.vue";
import CostReportitems from "@/views/projectsmanagement/CostReportDetailsItems.vue";
import SuplierStatementprojects from "@/views/projectsmanagement/SuplierStatement.vue";
import taxreportowners from "@/views/projectsmanagement/TaxReportOwners.vue";
import AllSupplierReceiptsproject from "@/views/projectsmanagement/AllSupplierReceipts.vue"
import CreateSupplierReceiptproject from "../views/projectsmanagement/CreateSupplierReceipt.vue";
import ContractorStatementprojects from "../views/projectsmanagement/ContractorStatement.vue";
import ContractorContractView from "../views/projectsmanagement/ContractorContractView.vue"
import CostReportDetailsClass from "@/views/projectsmanagement/CostReportDetailsClass.vue";
import contractorview from "@/views/projectsmanagement/ContractorView.vue";
import AllProductTransfer from "@/views/projectsmanagement/AllProductTransfer.vue";
import CreateProductTransfer from "@/views/projectsmanagement/CreateProductTransfer.vue";
import AllReturnsBills from "@/views/projectsmanagement/AllReturnsBills.vue";
import CreateReturnBill from "@/views/projectsmanagement/CreateReturnBill.vue";
import ReturnBillView from "@/views/projectsmanagement/ReturnBillView.vue";
// manufacturing
import AllManufacturingOrders from "@/views/stock/AllManufacturingOrders.vue"
import AddManufacturingOrder from "@/views/stock/AddManufacturingOrder.vue"
import ManufacturingOrderView from "@/views/stock/ManufacturingOrderView.vue";

const routes = [
  // roles
  {
    path: "/AdminDashboard/roles",
    name: "roles",
    component: RoleList,
  },
  {
    path: "/AdminDashboard/roles/create",
    name: "rolesCreate",
    component: RoleAdd,
  },
  {
    path: "/AdminDashboard/roles/:id",
    name: "rolesEdit",
    component: RoleEdit,
  },

  {
    path: "/403",
    name: "Error-403",
    component: Error403,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error-404",
    component: Error404,
  },
  {
    path: "/",
    name: "HomeSite",
    component: signIn,
  },
  {
    path: "/SignIn",
    name: "signIn",
    component: signIn,
  },
  {
    path: "/AdminDashboard/ChangePassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/accounting",
    name: "accounting",
    component: accounting,
    children: [
      {
        path: "/accounting/home",
        name: "homeaccounting",
        component: homeaccounting,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/ChartOfAccounts",
        name: "ChartOfAccounts",
        component: ChartOfAccounts,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/ChartOfAccounts/AddAccount",
        name: "AddAccount",
        component: AddAccount,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/JournalEntry",
        name: "JournalEntry",
        component: JournalEntry,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/JournalEntry/JournalEntryReview/:id",
        name: "JournalEntryReview",
        component: JournalEntryReview,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/costcenter",
        name: "costcenter",
        component: costcenter,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/addcostcenter",
        name: "addcostcenter",
        component: addcostcenter,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/JournalEntry/CreateJournalEntry",
        name: "CreateJournalEntry",
        component: CreateJournalEntry,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/AllFastJL",
        name: "FastJL",
        component: FastJL,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/AllFastJL/CreateJEntryTemplete",
        name: "CreateJEntryTemplete",
        component: CreateJEntryTemplete,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/CreateJournalEntryFast/:id",
        name: "CreateJournalEntryFast",
        component: CreateJournalEntryFast,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/OpeningBalance",
        name: "OpeningBalance",
        component: OpeningBalance,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/OpeningBalance2/:id",
        name: "OpeningBalance2",
        component: OpeningBalance2,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/GeneralLedgerSummary",
        name: "GeneralLedgerSummary",
        component: GeneralLedgerSummary,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/GeneralLedgerSummaryMonthly/:id/:namear/:nameen/:typeact",
        name: "GeneralLedgerSummaryMonthly",
        component: GeneralLedgerSummaryMonthly,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/GeneralLedgerSummaryDaily/:id/:namear/:nameen/:start/:end/:typeact",
        name: "GeneralLedgerSummaryDaily",
        component: GeneralLedgerSummaryDaily,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/JournalReport",
        name: "JournalReport",
        component: JournalReport,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/TrialBalance",
        name: "TrialBalance",
        component: TrialBalance,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/IncomeStatement",
        name: "IncomeStatement",
        component: IncomeStatement,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/FinancialCenter",
        name: "FinancialCenter",
        component: FinancialCenter,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/AllCommissions",
        name: "AllCommissions",
        component: AllCommissions,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/AllCommissions/AddCommission",
        name: "AddCommission",
        component: AddCommission,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
      {
        path: "/accounting/RevenuesReport",
        name: "RevenuesReport",
        component: RevenuesReport,
        meta: {
          requiresAuth: true,
          permissions: ["accounting_module"],
        },
      },
    ],
  },
  {
    path: "/purchases",
    name: "purchases",
    component: purchases,
    children: [
      {
        path: "/purchases/home",
        name: "homepurchases",
        component: homepurchases,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllPurchaseOrder",
        name: "AllPurchaseOrder",
        component: AllPurchaseOrder,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/PurchaseOrder/:id",
        name: "PurchaseOrderView",
        component: PurchaseOrderView,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllPurchaseOrder/CreatePurchaseOrder",
        name: "CreatePurchaseOrder",
        component: CreatePurchaseOrder,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts",
        name: "AllProducts",
        component: AllProducts,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts/NewProduct",
        name: "NewProducts",
        component: NewProducts,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts/NewProduct2/:id",
        name: "NewProducts2",
        component: NewProducts2,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },

      {
        path: "/purchases/AllProducts/ProductCategories",
        name: "ProductCategories",
        component: ProductCategories,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },

      {
        path: "/purchases/AllProducts/AddNewProductCategories",
        name: "AddNewProductCategories",
        component: AddNewProductCategories,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts/EditeProductCategories/:id",
        name: "EditeProductCategories",
        component: EditeProductCategories,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },

      {
        path: "/purchases/AllProducts/UnitTypes",
        name: "UnitTypes",
        component: UnitTypes,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts/AddNewUnitType",
        name: "AddNewUnitType",
        component: AddNewUnitType,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts/productview/:id",
        name: "productview",
        component: productview,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts/productedite/:id",
        name: "productedite",
        component: productedite,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },

      {
        path: "/purchases/AllProducts/AllProductions",
        name: "AllProduction",
        component: AllProduction,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllProducts/AddProduction",
        name: "AddProdction",
        component: AddProdction,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/suppliers",
        name: "suppliers",
        component: suppliers,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllBills",
        name: "AllBills",
        component: AllBills,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },


      {
        path: "/purchases/AllBills/BillView/:id",
        name: "BillView",
        component: BillView,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllBills/CreateBill",
        name: "CreateBill",
        component: CreateBill,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "purchases/SuplierStatement",
        name: "SuplierStatement",
        component: SuplierStatement,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllSupplierReceipts",
        name: "AllSupplierReceipts",
        component: AllSupplierReceipts,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },
      {
        path: "/purchases/AllSupplierReceipts/CreateSupplierReceipt",
        name: "CreateSupplierReceipt",
        component: CreateSupplierReceipt,
        meta: {
          requiresAuth: true,
          permissions: ["purchases_module"],
        },
      },


    ],
  },
  {
    path: "/sales",
    name: "sales",
    component: sales,
    children: [
      {
        path: "/sales/home",
        name: "homeSales",
        component: homeSales,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
      {
        path: "/sales/Customers",
        name: "Customers",
        component: Customers,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
      {
        path: "/sales/AllInvoices",
        name: "AllInvoices",
        component: AllInvoices,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
      {
        path: "/sales/AllInvoices/CreateInvoice",
        name: "CreateInvoice",
        component: CreateInvoice,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },

      {
        path: "/sales/InvoiceView/:id",
        name: "InvoiceView",
        component: InvoiceView,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
      {
        path: "/sales/CustomerStatement",
        name: "CustomerStatement",
        component: CustomerStatement,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
      {
        path: "/sales/AllReceipts",
        name: "AllReceipts",
        component: AllReceipts,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
      {
        path: "/sales/AllReceipts/CreateReceipt",
        name: "CreateReceipt",
        component: CreateReceipt,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
      {
        path: "/sales/AddProductsToCustomer",
        name: "AddProductsToCustomer",
        component: AddProductsToCustomer,
        meta: {
          requiresAuth: true,
          permissions: ["sales_module"],
        },
      },
    ],
  },
  {
    path: "/stock",
    name: "stock",
    component: StockModul,
    children: [
      {
        path: "/stock/HomeStock",
        name: "HomeStock",
        component: HomeStock,
        meta: {
          requiresAuth: true,
          permissions: ["inventory_module"],
        },
      },
      {
        path: "/stock/AllBuyAndSell",
        name: "AllBuyAndSell",
        component: AllBuyAndSell,
        meta: {
          requiresAuth: true,
          permissions: ["inventory_module"],
        },
      },
      {
        path: "/stock/AllBuyAndSellP",
        name: "AllBuyAndSellP",
        component: AllBuyAndSellP,
        meta: {
          requiresAuth: true,
          permissions: ["inventory_module"],
        },
      },
      {
        path: "/stock/StockBalance",
        name: "StockBalance",
        component: StockBalance,
        meta: {
          requiresAuth: true,
          permissions: ["inventory_module"],
        },
      },
      {
        path: "/stock/ProductStockBalance",
        name: "ProductStockBalance",
        component: ProductStockBalance,
        meta: {
          requiresAuth: true,
          permissions: ["inventory_module"],
        },
      },
      {
        path: "/stock/damagedproducts",
        name: "damagedproducts",
        component: damagedproducts,
        meta: {
          requiresAuth: true,
          permissions: ["inventory_module"],
        },
      },
    ],
  },
  {
    path: "/tasks",
    name: "tasks",
    component: TaskModul,
    children: [
      { path: "/taskshome", name: "taskshome", component: TaskHome },
      {
        path: "/tasks/AllTasksView",
        name: "AllTasksView",
        component: AllTasksView,
        meta: {
          requiresAuth: true,
          permissions: ["tasks_module"],
        },
      },
      {
        path: "/tasks/CreateNewTask",
        name: "CreateNewTask",
        component: CreateNewTask,
        meta: {
          requiresAuth: true,
          permissions: ["tasks_module"],
        },
      },
      {
        path: "/tasks/taskview/:id",
        name: "taskview",
        component: TaskView,
        meta: {
          requiresAuth: true,
          permissions: ["tasks_module"],
        },
      },
      {
        path: "/tasks/collectingorders",
        name: "collectingorders",
        component: collectingorders,
        meta: {
          requiresAuth: true,
          permissions: ["tasks_module"],
        },
      },
      {
        path: "/tasks/AllSupplyOrder",
        name: "AllSupplyOrder",
        component: AllSupplyOrder,
        meta: {
          requiresAuth: true,
          permissions: ["tasks_module"],
        },
      },
      {
        path: "/tasks/supplierorder/:id",
        name: "supplierorder",
        component: supplierorder,
        meta: {
          requiresAuth: true,
          permissions: ["tasks_module"],
        },

      },
    ],
  },
  {
    path: "/CRM",
    name: "CRM",
    component: CRMModul,
    children: [
      { path: "/HomeCRM", name: "HomeCRM", component: HomeCRM },
      {
        path: "/CRM/AllRequestsView",
        name: "AllRequestsView",
        component: AllRequestsView,
        meta: {
          requiresAuth: true,
          permissions: ["crm_module"],
        },

      },
      {
        path: "/CRM/CreateNewRequest",
        name: "CreateNewRequest",
        component: CreateNewRequest,
        meta: {
          requiresAuth: true,
          permissions: ["crm_module"],
        },
      },
      {
        path: "/tasks/Requestview/:id",
        name: "Requestview",
        component: Requestview,
        meta: {
          requiresAuth: true,
          permissions: ["crm_module"],
        },
      },
      {
        path: "/tasks/CreateNewPO",
        name: "CreateNewPO",
        component: CreateNewPO,
        meta: {
          requiresAuth: true,
          permissions: ["crm_module"],
        },
      },
    ],
  },
  {
    path: "/property",
    name: "property",
    component: property,
    children: [
      { path: "/HomeProperty", name: "HomeProperty", component: HomeProperty },
      {
        path: "/property/allproperties",
        name: "allproperties",
        component: allproperties,
        meta: {
          requiresAuth: true,
          permissions: ["property_list"],
        },
      },
      {
        path: "/property/createproperty",
        name: "createproperty",
        component: createproperty,
        meta: {
          requiresAuth: true,
          permissions: ["property_add"],
        },
      },
      {
        path: "/property/createowner",
        name: "createowner",
        component: createowner,
        meta: {
          requiresAuth: true,
          permissions: ["client_add"],
        },
      },
      {
        path: "/property/allcustomers",
        name: "allcustomers",
        component: allcustomers,
        meta: {
          requiresAuth: true,
          permissions: ["client_list"],
        },
      },
      {
        path: "/property/propertyview/:id",
        name: "propertyview",
        component: propertyview,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/propertydetails/:id",
        name: "propertydetails",
        component: propertydetails,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/ContactView/:id",
        name: "ContactView",
        component: ContactView,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/createpayment/:conid/:pid",
        name: "createpayment",
        component: createpayment,
        meta: {
          requiresAuth: true,
          permissions: ["receipt_voucher_add"],
        },
      },
      {
        path: "/property/allpayments",
        name: "allpayments",
        component: allpayments,
        meta: {
          requiresAuth: true,
          permissions: ["receipt_vouchers"],
        },
      },
      {
        path: "/property/renterstatement",
        name: "renterstatement",
        component: renterstatement,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/ownerstatement",
        name: "ownerstatement",
        component: ownerstatement,
        meta: {
          requiresAuth: true,
          permissions: ["owner_account_statement"],
        },
      },
      {
        path: "/property/rentalstatement",
        name: "rentalstatement",
        component: rentalstatement,
        meta: {
          requiresAuth: true,
          permissions: ["rent_due_statement"],
        },
      },
      {
        path: "/property/Customerstatement",
        name: "Customerstatement",
        component: Customerstatement,
        meta: {
          requiresAuth: true,
          permissions: ["rent_due_statement"],
        },
      },

      {
        path: "/property/allcustomers/customerview/:id",
        name: "CustomerView",
        component: CustomerView,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/createcontract/:id/:name",
        name: "CreateContract",
        component: CreateContract,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/paymentview/:id",
        name: "PaymentView",
        component: PaymentView,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/createpriceoffer",
        name: "createpriceoffer",
        component: createpriceoffer,
        meta: {
          requiresAuth: true,
          permissions: ["price_quotation_add"],
        },
      },
      {
        path: "/property/allpropertiespriceoffer",
        name: "allpropertiespriceoffer",
        component: allpropertiespriceoffer,
        meta: {
          requiresAuth: true,
          permissions: ["price_quotation_view"],
        },
      },
      {
        path: "/property/propertypriceofferview/:id",
        name: "propertypriceofferview",
        component: propertypriceofferview,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },

      {
        path: "/property/RevenuesPropertiesReport",
        name: "RevenuesPropertiesReport",
        component: RevenuesPropertiesReport,
        meta: {
          requiresAuth: true,
          permissions: ["revenue_report"],
        },
      },

      {
        path: "/property/CreateReceiptOwner",
        name: "CreateReceiptOwner",
        component: CreateReceiptOwner,
        meta: {
          requiresAuth: true,
          permissions: ["payment_voucher_add"],
        },
      },
      {
        path: "/property/AllOwnerReceipts",
        name: "AllOwnerReceipts",
        component: AllOwnerReceipts,
        meta: {
          requiresAuth: true,
          permissions: ["payment_vouchers"],
        },
      },
      {
        path: "/property/ownerreceiptview/:id",
        name: "ownerreceiptview",
        component: ownerreceiptview,
        meta: {
          requiresAuth: true,
          permissions: ["property_module"],
        },
      },
      {
        path: "/property/RevenuesReportDetails/:id/:from/:to",
        name: "RevenuesReportDetails",
        component: RevenuesReportDetails,
        meta: {
          requiresAuth: true,
          permissions: ["revenue_report"],
        },
      },
      {
        path: "/property/contractstatment",
        name: "contractstatment",
        component: contractstatment,
        meta: {
          requiresAuth: true,
          permissions: ["client_contracts"],
        },
      },
      {
        path: "/property/TaxReportOwners",
        name: "TaxReportOwners",
        component: TaxReportOwners,
        meta: {
          requiresAuth: true,
          permissions: ["tax_report_owners"]
        },
      },
      {
        path: "/property/ExpiredContracts",
        name: "ExpiredContracts",
        component: ExpiredContracts,
        meta: {
          requiresAuth: true,
          permissions: ["expired_contract_report"],

        },
      },
      {
        path: "/property/ArchiveContracts",
        name: "ArchiveContracts",
        component: ArchiveContracts,
        meta: {
          requiresAuth: true,
          permissions: ["expired_contract_report"],

        },
      },
    ],
  },
  {
    path: "/ProjectsManagement",
    name: "ProjectsManagement",
    component: ProjectsManagement,
    children: [
      {
        path: "/HomeProjects", name: "HomeProjects", component: HomeProjects,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/allprojects", name: "allprojects", component: allprojects,
        meta: {
          requiresAuth: true,
          permissions: ["projects"],
        },
      },
      {
        path: "/ProjectsManagement/createproject", name: "createproject", component: createproject,
        meta: {
          requiresAuth: true,
          permissions: ["projects"],
        },
      },
      {
        path: "/ProjectsManagement/projectview/:id", name: "projectview", component: projectview,
        meta: {
          requiresAuth: true,
          permissions: ["projects"],
        },
      },
      {
        path: "/ProjectsManagement/allprojectsbills", name: "AllProjectsBills", component: AllProjectsBills,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/CreateProjectsBill", name: "CreateProjectsBill", component: CreateProjectsBill,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/ProjectsBillView/:id", name: "ProjectsBillView", component: ProjectsBillView,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/contractors", name: "contractors", component: contractors,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/contractorview/:id", name: "contractorview", component: contractorview,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },

      {
        path: "/ProjectsManagement/contractors/newcontractor", name: "newcontractor", component: newcontractor,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/contractors/allcontractorscontracts", name: "allcontractorscontracts", component: allcontractorscontracts,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/contractors/createcontractorcontract", name: "createcontractorcontract", component: createcontractorcontract,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/contractors/ContractorContractView/:id", name: "ContractorContractView", component: ContractorContractView,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },

      {
        path: "/ProjectsManagement/AllExtracts", name: "AllExtracts", component: AllExtracts,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllExtracts/CreateExtract", name: "CreateExtract", component: CreateExtract,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllExtracts/extractview/:id", name: "extractview", component: extractview,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllExtractsPayment", name: "AllExtractsPayment", component: AllExtractsPayment,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllExtractsPayment/CreateReceiptProject", name: "CreateReceiptProject", component: CreateReceiptProject,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllExtractsPayment/ReceiptProject/:id", name: "ReceiptProject", component: ReceiptProject,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllReceivePatty", name: "AllReceivePatty", component: AllReceivePatty,
        meta: {
          requiresAuth: true,
          permissions: ["projects_covenant"],
        },
      },
      {
        path: "/ProjectsManagement/AllReceivePatty/CreateReceivePatty", name: "CreateReceivePatty", component: CreateReceivePatty,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllReceivePatty/receivepattyview/:id", name: "receivepattyview", component: receivepattyview,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllReceivePatty/pattystatement", name: "pattystatement", component: pattystatement,
        meta: {
          requiresAuth: true,
          permissions: ["projects_covenant"],
        },
      },
      {
        path: "/ProjectsManagement/CostReport", name: "CostReport", component: CostReport,
        meta: {
          requiresAuth: true,
          permissions: ["cost_report"],
        },
      },
      {
        path: "/ProjectsManagement/CostReportDetailsClass", name: "CostReportDetailsClass", component: CostReportDetailsClass,
        meta: {
          requiresAuth: true,
          permissions: ["cost_report"],
        },
      },

      {
        path: "/ProjectsManagement/CostReportitems", name: "CostReportitems", component: CostReportitems,
        meta: {
          requiresAuth: true,
          permissions: ["cost_report"],
        },
      },
      {
        path: "/ProjectsManagement/SuplierStatementprojects", name: "SuplierStatementprojects", component: SuplierStatementprojects,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/ContractorStatementprojects", name: "ContractorStatementprojects", component: ContractorStatementprojects,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/taxreportowners", name: "taxreportowners", component: taxreportowners,
        meta: {
          requiresAuth: true,
          permissions: ["cost_report"],
        },
      },
      {
        path: "/ProjectsManagement/AllSupplierReceiptsproject", name: "AllSupplierReceiptsproject", component: AllSupplierReceiptsproject,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllSupplierReceipts/CreateSupplierReceiptproject",
        name: "CreateSupplierReceiptproject",
        component: CreateSupplierReceiptproject,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllProductTransfer",
        name: "AllProductTransfer",
        component: AllProductTransfer,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllProductTransfer/CreateProductTransfer",
        name: "CreateProductTransfer",
        component: CreateProductTransfer,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllReturnsBills",
        name: "AllReturnsBills",
        component: AllReturnsBills,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllReturnsBills/CreateReturnBill",
        name: "CreateReturnBill",
        component: CreateReturnBill,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },
      {
        path: "/ProjectsManagement/AllReturnsBills/ReturnBillView/:id",
        name: "ReturnBillView",
        component: ReturnBillView,
        meta: {
          requiresAuth: true,
          permissions: ["project_module"],
        },
      },

    ]
  },
  {
    path: "/manufacturing",
    name: "manufacturing",
    component: AdminDashboard,
    children: [
      {
        path: "/manufacturing/AllOrders",
        name: "AllManufacturingOrders",
        component: AllManufacturingOrders,
        meta: {
          requiresAuth: true,
          permissions: ["companies_list"],
        },
      },
      {
        path: "/manufacturing/AddManufacturingOrder",
        name: "AddManufacturingOrder",
        component: AddManufacturingOrder,
        meta: {
          requiresAuth: true,
          permissions: ["companies_list"],
        },
      },
      {
        path: "/manufacturing/AllOrders",
        name: "AllManufacturingOrders",
        component: AllManufacturingOrders,
        meta: {
          requiresAuth: true,
          permissions: ["companies_list"],
        },
      },
      {
        path: "/manufacturing/ManufacturingOrderView/:id",
        name: "ManufacturingOrderView",
        component: ManufacturingOrderView,
        meta: {
          requiresAuth: true,
          permissions: ["companies_list"],
        },
      },

    ]
  },

  {
    path: "/AdminDashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    children: [
      {
        path: "/AdminDashboard/AllContracts",
        name: "AllContracts",
        component: AllContracts,
        meta: {
          requiresAuth: true,
          permissions: ["companies_list"],
        },
      },
      // sales
      {
        path: "/AdminDashboard/Customers/NewCustomer",
        name: "NewCustomer",
        component: NewCustomer,
      },
      {
        path: "/AdminDashboard/Customers/CustomerCategorie",
        name: "CustomerCategorie",
        component: CustomerCategorie,
      },
      {
        path: "/AdminDashboard/Customers/ImportCustomerData",
        name: "ImportCustomerData",
        component: ImportCustomerData,
      },
      {
        path: "/AdminDashboard/Customers/editecustomer/:id",
        name: "EditeCustomer",
        component: EditeCustomer,
      },
      {
        path: "/AdminDashboard/Customers/customerdetails/:id",
        name: "customerdetails",
        component: customerdetails,
      },
      {
        path: "/AdminDashboard/Suppliers/NewSupplier",
        name: "NewSupplier",
        component: NewSupplier,
      },
      {
        path: "/AdminDashboard/OfferPrice",
        name: "OfferPrice",
        component: OfferPrice,
      },
      {
        path: "/AdminDashboard/SupplyOrder",
        name: "SupplyOrder",
        component: SupplyOrder,
      },
      {
        path: "/AdminDashboard/SupplyOrder/CreateSupplyOrder",
        name: "CreateSupplyOrder",
        component: CreateSupplyOrder,
      },
      {
        path: "/AdminDashboard/OfferPrice/CreateOfferPrice",
        name: "CreateOfferPrice",
        component: CreateOfferPrice,
      },
      {
        path: "/AdminDashboard/OfferPrice/:id",
        name: "OfferPriceView",
        component: OfferPriceView,
      },
      {
        path: "/AdminDashboard/SupplyOrderView/:id",
        name: "SupplyOrderView",
        component: SupplyOrderView,
      },

      {
        path: "/AdminDashboard/Suppliers/supplierdetails/:id",
        name: "supplierdetails",
        component: supplierdetails,
      },
      // branches
      {
        path: "/AdminDashboard/AllBranches",
        name: "AllBranches",
        component: AllBranches,
      },
      {
        path: "/AdminDashboard/AllBranches/AddNewBranch",
        name: "AddNewBranch",
        component: AddNewBranch,
      },
      // Account

      {
        path: "/AdminDashboard/JournalEntry/AllOperationExpenses",
        name: "AllOperationExpenses",
        component: AllOperationExpenses,
      },
      {
        path: "/AdminDashboard/JournalEntry/DelegatesDues",
        name: "DelegatesDues",
        component: DelegatesDues,
      },
      {
        path: "/AdminDashboard/AllStoresTransactions",
        name: "AllStoresTransactions",
        component: AllStoresTransactions,
      },

      // projects

      {
        path: "/AdminDashboard/AllProjects",
        name: "AllProjects",
        component: AllProjects,
      },
      {
        path: "/AdminDashboard/AllProjects/NewProject",
        name: "NewProject",
        component: NewProject,
      },
      // tasks
      {
        path: "/AdminDashboard/AllTasks",
        name: "AllTasks",
        component: AllTasks,
      },
      {
        path: "/AdminDashboard/AllTasks/NewTask",
        name: "NewTask",
        component: NewTask,
      },
      // AllReports
      {
        path: "/AdminDashboard/AllReports",
        name: "AllReports",
        component: AllReports,
      },
      {
        path: "/AdminDashboard/AllReports/BalanceSheet",
        name: "BalanceSheet",
        component: BalanceSheet,
      },

      {
        path: "/AdminDashboard/Home",
        name: "Home",
        component: Home,
      },

      // users

      {
        path: "/AdminDashboard/RAndP",
        name: "RAndP",
        component: RAndP,
        meta: {
          requiresAuth: true,
          permissions: ["user_list"],
        },
      },
      {
        path: "/AdminDashboard/user/userdetails/:id",
        name: "UserView",
        component: UserView,
        meta: {
          requiresAuth: true,
          permissions: ["user_list"],
        },
      },
      {
        path: "/AdminDashboard/user/useredite/:id/:username",
        name: "UserEdite",
        component: UserEdite,
        meta: {
          requiresAuth: true,
          permissions: ["user_edit"],
        },
      },
      {
        path: "/AdminDashboard/RAndP/AddUser",
        name: "AddUser",
        component: AddUser,
        meta: {
          requiresAuth: true,
          permissions: ["user_create"],
        },
      },
      {
        path: "/AdminDashboard/CompanyContracts",
        name: "CompanyContracts",
        component: CompanyContracts,
      },
      {
        path: "/AdminDashboard/CompanyContracts/AddCompanyContracts",
        name: "AddCompanyContracts",
        component: AddCompanyContracts,
      },
      ...rolesRoutes,
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard for checking permissions
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let userPermissions = store.state.userPermissions;
    if (!userPermissions || userPermissions.length === 0) {
      console.error("Failed to fetch user permissions:");
      next({ name: "Home" });
      return;
    }
    const requiredPermissions = to.meta.permissions || [];
    const hasPermission = requiredPermissions.every((permission) =>
      userPermissions.includes(permission)
    );
    if (!hasPermission) {
      next({ name: "Error-403" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
