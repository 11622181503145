<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#" class="active">{{ $t("Property") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active">{{ $t("Account Statement") }} {{ $t("renter") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("renter") }}</span> &nbsp;&nbsp;
        <v-select :options="accounts" label="name" v-model="item"
          style="background-color: white; color: black; width: 50%">
          <template #option="option">
            <span style="color: black"> {{ option.name }}</span>
          </template> </v-select>&nbsp;&nbsp;
        {{ $t("from") }}
        <input type="date" :placeholder="$t('from')" v-model="from" />
        {{ $t("to") }}
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
    </div>
    <div class="body" v-if="this.view == 0">برجاء اختيار اسم العميل</div>
    <div class="body" v-if="this.view != 0" id="main">
      <!-- <img :src="this.url" crossorigin="anonymous" /> -->

      <br />
      <div style="padding: 2%; direction: rtl; font-size: small">
        <h4>
          {{ $t("Account Statement") }} {{ $t("renter") }} -
          <span v-if="css.lang == 'ar'">{{ namear }}</span><span v-if="css.lang == 'en'">{{ nameen }}</span> {{ view }}
        </h4>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead>
            <tr>
              <th>
                {{ $t("number") }} {{ $t("contract") }}
              </th>
              <th>
                {{ $t("realty") }}
              </th>
              <th>
                {{ $t("amount") }}
              </th>
              <th>
                {{ $t("date") }} {{ $t("start") }}
              </th>
              <th>
                {{ $t("date") }} {{ $t("end") }}
              </th>
              <th>
                {{ $t("status") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in allcontract" :key="x">
              <td>{{ x.conid }}</td>
              <td>{{ x.propertyname }}</td>
              <td>{{ x.contamount }}</td>
              <td>{{ x.contstart }}</td>
              <td>{{ x.contend }}</td>
              <td>{{ x.status }}</td>
            </tr>
          </tbody>
        </table>


        <table class="table">
          <thead>
            <tr>
              <th>المديونية</th>
              <th>المدفوع</th>
              <th>الرصيد الافتتاحي</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ opendueamount }}</td>
              <td>{{ paied }}</td>
              <td>{{ openbalance }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("realty") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("type") }}</th>
              <th>{{ $t("description") }}</th>
              <th>
                {{ $t("dpt") }}
              </th>
              <th>
                {{ $t("crd") }}
              </th>
              <th>{{ $t("balance") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in data1" :key="i">
              <td>
                <router-link to=""><span v-if="i.collid">{{ i.collid }}</span></router-link>
              </td>
              <td>{{ i.propertyname }}</td>
              <td>{{ i.date }}</td>
              <td><span v-if="i.duetype == 'installment'">قسط</span><span v-if="i.duetype == 'commission'">عمولة</span>
              </td>
              <td><span v-if="!i.duetype" style="color: green;">سداد</span><span v-if="i.duetype"
                  style="color: red;">استحقاق</span></td>
              <td>
                {{ i.dpt }}
              </td>
              <td>
                {{ i.crd }}
              </td>
              <td>{{ i.balance }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4"></td>
              <td>المديونية</td>
              <td>{{ totalbalance }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="buttom">
      <button class="buttonconfirm" type="submit" v-if="this.view != 0" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import store from "@/store";
export default {
  components: {
    vSelect,
  },
  async mounted() {
    const result2 = await axios.get("/data/allcustomer");
    if (result2.status == 200) {
      const cust = result2.data.data;
      this.accounts = cust.filter((x) => x.type == "renter");
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      item: {},
      fromdate: "",
      todate: "",
      data1: [],
      view: "",
      namear: "",
      nameen: "",
      typeact: "",
      accounts: [],
      openbalance: "",
      totalbalance: 0,
      totaldpt: 0,
      totalcrd: 0,
      from: "",
      to: "",
      url: "",
      renteraccount: "",
      totalannualrent: 0,
      totalcom: 0,
      totalamount: 0,
      remaining: 0,
      allcontract: [],
      taxaccount: "",
      paied: "",
      opendueamount: ""
    };
  },
  methods: {
    async submit() {
      const result = await axios.get(
        `/reports/customerstatementproperty/${this.from}/${this.to}/${this.item.cid}`
      );
      if (result.status == 200) {
        this.allcontract = result.data.data1;
        this.view = this.item.actnum;
        this.fromdate = this.from;
        this.todate = this.to;
        this.namear = this.item.name;
        this.nameen = this.item.name;
        // const data2 = result.data.data[0];
        const data = result.data.data;
        this.opendueamount = data[0];
        this.paied = data[1];
        this.openbalance = Number(data[0] - data[1]).toFixed(2);
        const alldata = result.data.data2;
        this.totalbalance = this.openbalance;
        this.data1 = []

        for (let i = 0; i < alldata.length; i++) {
          if (alldata[i].duetype != null) {
            this.totalbalance = Number(
              Number(this.totalbalance) +
              Number(alldata[i].amount)
            ).toFixed(2);
            this.data1.push({
              collid: alldata[i].collid,
              propertyname: alldata[i].propertyname,
              duetype: alldata[i].duetype,
              date: alldata[i].date,
              dpt: Number(alldata[i].amount),
              crd: 0,
              balance: Number(this.totalbalance),
            });
          } else {
            this.totalbalance = Number(
              Number(this.totalbalance) -
              Number(alldata[i].amount)
            ).toFixed(2);
            this.data1.push({
              collid: alldata[i].collid,
              propertyname: alldata[i].propertyname,
              duetype: alldata[i].duetype,
              date: alldata[i].date,
              dpt: 0,
              crd: Number(alldata[i].amount),
              balance: Number(this.totalbalance),
            });
          }

        }
        // const due = this.data1.filter((x) => x.duetype != null);
        // const totaldue = due.reduce((acc, array) => acc + Number(array.amount), 0)
        // const paied = this.data1.filter((x) => x.duetype == null);
        // const paieddue = paied.reduce((acc, array) => acc + Number(array.amount), 0)
        // this.totalbalance = Number(Number(this.openbalance) + Number(totaldue) - paieddue).toFixed(2)
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.1,
        filename: `Statement-${this.item.name}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print2() {
      this.$htmlToPaper("main");
    },
  },
  watch: {
    // item() {
    //   const data = JSON.parse(this.item.account)
    //   const data2 = JSON.parse(this.item.taxaccount)
    //   this.renteraccount = data.actnum;
    //   this.taxaccount = data2.actnum;
    // },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
